<template>
  <div class="list">
    <div
      @click="toRead(item)"
      class="item"
      v-for="(item, index) in readingList"
      :key="index"
    >
      <div class="pic-block">
        <img class="pic" :src="item.resources_cover" alt=""/>
      </div>
      <div class="item-info">
        <div class="info-title">
          {{ item.resources_name }}
        </div>
        <div class="info-desc">
          {{ item.resources_desc }}
        </div>
        <div class="info-author">
          <div class="icon"></div>
          <div class="author">{{ item.resources_author }}</div>
          <div class="label">{{ item.resources_type }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadingList',
  props: {
    readingList: {
      type: Array,
      default: null
    }
  },
  methods: {
    toRead (item) {
      this.$router.push('/home/reading/' + this.$route.params.order + '/book-detail/' + item.resources_guid + '/' + item.resources_name)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.list
  width 100%

  .item
    display flex
    width 690px
    height 268px
    margin 0 auto 9px auto
    background #FFFFFF
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
    border-radius 10px

    .pic-block
      width 166px
      height 228px
      margin 20px
      border-radius 10px
      overflow hidden

      .pic
        width 100%
        height 100%

    .item-info
      width 478px
      height 228px
      margin-left 6px
      margin-top 20px

      .info-title
        width 478px
        height 30px
        line-height 34px
        font-size 26px
        font-weight 500
        color rgba(51, 51, 51, 1)
        margin-top 9px
        no-wrap()

      .info-desc
        width 478px
        height 60px
        line-height 32px
        font-size 24px
        font-weight 500
        color rgba(153, 153, 153, 1)
        margin-top 52px
        no-wrap2(2)

    .info-author
      display flex
      align-items center
      width 478px
      height 32px
      margin-top 36px

      .icon
        width 16px
        height 16px
        bg-image('~@/assets/img/reading/author')
        background-size 100% 100%
        background-repeat no-repeat

      .author
        width 338px
        line-height normal
        font-size 22px
        font-weight 500
        color rgba(153, 153, 153, 1)
        margin-left 10px
        no-wrap()

      .label
        display flex
        align-items center
        justify-content center
        padding 0 10px
        //width 80px
        height 30px
        line-height normal
        font-size 20px
        font-weight 500
        color rgba(245, 151, 1, 1)
        border-radius 6px
        border 1px solid rgba(245, 151, 1, 1)
</style>
