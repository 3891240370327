<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="reading">
      <reading-header @changeClass="changeClass"></reading-header>
      <scroll
        ref="scroll"
        class="scroll"
        :data="readingList"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <reading-list v-if="readingList.length" :reading-list="readingList"></reading-list>
          <div class="home-bottom" v-show="showDivider">
            <divider class="divider">已加载全部内容</divider>
          </div>
        </div>
      </scroll>
      <router-view></router-view>
    </div>
  </transition>
</template>
<script>
import Scroll from '@/components/scroll/scroll'
import ReadingHeader from './components/Header'
import ReadingList from './components/List'
import { Divider, Toast } from 'vant'
import { getResourcesPageList } from '@/api/Resources'
import { mapGetters } from 'vuex'

export default {
  name: 'Reading',
  components: {
    Scroll,
    ReadingHeader,
    ReadingList,
    Divider
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'openid'
    ])
  },
  mounted () {
    this._getResourcesPageList()
  },
  data () {
    return {
      order: this.$route.params.order,
      page: 1, // 分页器
      limit: 10, // 分页显示条目
      categoryGuid: '', // 分类主键
      readingList: [], // 书籍列表
      showDivider: false, // 加载全部显示标记
      isPullingDown: false
    }
  },
  methods: {
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    _getResourcesPageList () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: [1, 5],
        page: this.page,
        limit: this.limit,
        category_guid: this.categoryGuid,
        field: this.$route.params.order,
        order: 'desc'
      }).then(res => {
        if (res.code === 200) {
          this.readingList = this.readingList.concat(res.data.list)
          if (res.data.count <= this.readingList.length) {
            this.showDivider = true
          }
        }
        Toast.clear()
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.readingList.splice(0, this.readingList.length)
      this.showDivider = false
      this._getResourcesPageList()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.page++
      this._getResourcesPageList()
    },
    changeClass (categoryGuid) {
      this.categoryGuid = categoryGuid
      this.page = 1
      this.readingList.splice(0, this.readingList.length)
      this.showDivider = false
      this._getResourcesPageList()
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.reading
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 106px
    left 0
    right 0
    bottom 0
    overflow hidden

    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)

    .home-bottom
      wdith 750px
      height 70px
      padding-top 30px

      .divider
        width 300px
        height 22px
        font-size 22px
        color rgba(153, 153, 153, 1)
        margin 0 auto
        border-color rgba(153, 153, 153, 1)
</style>
